<template>
    <div :class="['rating-banner', { webmaster: isWebmasterRole }]">
        <div class="rating-banner_wrapper-info">
            <div class="rating-banner_wrapper-title">
                <img
                    alt="logo"
                    class="rating-banner_title"
                    src="@/assets/Competition/Raffle/title.svg">
            </div>

            <div class="rating-banner_info">
                <div
                    class="flex"
                    style="margin-bottom: clamp(0.875rem, 0.7105rem + 0.6579vw, 1.5rem);">
                    <span class="date">
                        15/01
                    </span>
                    <img
                        alt="arrow"
                        class="mx-3"
                        src="@/assets/Competition/Raffle/arrow.svg">
                    <span class="date">
                        15/02
                    </span>
                </div>

                <div class="flex">
                    <div class="rating-banner_subtitle">
                        <img
                            alt="logo"
                            src="@/assets/Competition/Raffle/logo.png">
                        <span class="pre-wrap">
                            {{ $t('common.rating.banner.subtitle') }}
                        </span>
                    </div>
                    <template v-if="isWebmasterRole">
                        <div class="rating-banner_button button-point">
                            <span class="pre-wrap">
                                {{ $t("common.rating.banner.buttons.point") }}
                            </span>
                            <div class="rating-banner_button-info">
                                {{ formatEmptyString(currentCompetition && currentCompetition.balance) }}
                            </div>
                        </div>
                        <div class="rating-banner_button button-place">
                            <span class="pre-wrap">
                                {{ $t("common.rating.banner.buttons.place") }}
                            </span>
                            <div class="rating-banner_button-info">
                                {{ formatEmptyString(currentCompetition && currentCompetition.place) }}
                            </div>
                        </div>
                    </template>
                    <template v-if="isWebmasterRole && hasDashboard">
                        <router-link
                            :to="{
                                name: 'webmaster:competitionRating',
                                query: {
                                    backLink: $route.fullPath,
                                    filters: encodeFilters({ inCompetition: true })
                                }
                            }"
                            class="rating-banner_button tournament-table">
                            <img
                                alt="tournament-table"
                                class="mr-1"
                                src="@/assets/Competition/Raffle/Tournament-Table.svg">
                            <img
                                alt="tournament-table"
                                class="mr-1"
                                src="@/assets/Competition/Raffle/Tournament-Table.svg">
                            <img
                                alt="tournament-table"
                                class="mr-3"
                                src="@/assets/Competition/Raffle/Tournament-Table.svg">
                            <span>
                                {{ $t("common.rating.banner.buttons.tournamentTable") }}
                            </span>
                        </router-link>
                    </template>
                    <b-button
                        tag="a"
                        target="_blank"
                        href="https://squidgame-lmnd.online/"
                        class="rating-banner_button show-more">
                        <span>
                            {{ $t("admin.dashboard.competition.banner.button") }}
                        </span>
                    </b-button>
                </div>
            </div>
        </div>

        <CompetitionRatingPrizesCard></CompetitionRatingPrizesCard>
    </div>
</template>

<script>
  import CompetitionRatingPrizesCard from "@/components/Common/Competition/CompetitionRatingPrizesCard.vue";
  import { formatEmptyString } from "@/filters";
  import { mapGetters, mapState } from "vuex";
  import { encodeFilters } from "@core/filters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_OPTIONS } from "@core/store/action-constants";

  export default {
    name: "CompetitionRatingBanner",

    components: {
      CompetitionRatingPrizesCard
    },

    props: {
      place: {
        type: Number,
        default: null
      }
    },

    mounted () {
      window.addEventListener("resize", this.setMarginLeft);
      this.setMarginLeft();
    },

    data () {
      return {
        titleElementWidth: 96
      };
    },

    computed: {
      ...mapGetters(["role"]),

      ...mapState("competition", {
        currentCompetition: ({ currentCompetition }) => currentCompetition
      }),

      ...mapFields("competition", {
        fields: ["isActive"],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      isWebmasterRole () {
        return this.role === "webmaster";
      },

      hasDashboard () {
        return this.$route.name === "dashboard";
      }
    },

    methods: {
      encodeFilters,
      formatEmptyString,

      setMarginLeft () {
        this.titleElementWidth = this.$refs.firstTitleElement?.offsetWidth;
      }
    },

    destroyed () {
      window.removeEventListener("resize", this.setMarginLeft);
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style scoped lang="scss">
  @import "~@/scss/competition-banner";

  .columns:not(:last-child) {
    margin-bottom: -0.75rem !important;
  }

  .pre-wrap {
    white-space: pre;
  }

  .date {
    font-weight: 700;
    font-size: clamp(1.875rem, -1.3221rem + 3.6538vw, 3.0625rem);
    line-height: 1.06;
    letter-spacing: -0.05em;
    text-shadow: 1px 2px 4px #3c3b3b;

    @media screen and (max-width: 820px) {
      font-size: clamp(1.125rem, -0.026rem + 5.4167vw, 2.75rem);
    }
  }

  .rating-banner {
    $self: &;

    #{$self}_wrapper-info,
    #{$self}_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    #{$self}_wrapper-title {
      margin-bottom: 8px;
    }


    #{$self}_title {
      display: flex;
      align-items: center;

      width: clamp(30rem, -12.0673rem + 48.0769vw, 45.625rem);
      position: absolute;
      top: 10px;

      letter-spacing: -0.04em;
      line-height: 1;
      color: white;
      font-size: clamp(33px, 3.4vw, 68px);
      font-weight: 800;

      @media screen and (max-width: 820px) {
        width: clamp(16.25rem, 4.7396rem + 54.1667vw, 32.5rem);
      }

      span {
        position: relative;
        padding: 10px 9px;
        margin-right: 4px;

        box-shadow: 0 0 24px 0 rgba(191, 255, 84, 0.31);
        background: linear-gradient(134deg, #2e4a07 0%, #719d34 100%);
        border: clamp(2px, 0.7vw, 4px) solid white;

        @media screen and (max-width: 820px) {
          padding: clamp(4px, 1.2vw, 10px) !important;
        }

        &::before {
          content: "35";
          position: absolute;
          top: 5px;
          right: 3px;

          font-weight: 500;
          font-size: 13px;
          letter-spacing: -0.05em;

          @media screen and (max-width: 820px) {
            font-size: clamp(6px, 1.5vw, 11px);
          }
        }
      }
    }

    #{$self}_info {
      display: flex;
    }

    .button-place {
      #{$self}_button-info {
        background-color: $second-color;
        background-image: none;
      }
    }

    #{$self}_button {
      display: flex;
      align-items: center;

      padding: 4px;
      margin-left: 8px;

      border-radius: 32px;
      background-color: black;

      span {
        margin-left: clamp(8px, 0.65vw, 12px);
        margin-right: clamp(10px, 0.65vw, 16px);

        font-weight: 500;
        font-size: clamp(9px, 0.65vw, 13px);
        line-height: 1.38;
        letter-spacing: -0.04em;
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;
        padding: 4px 10px;

        min-width: clamp(3.3125rem, 2.8355rem + 1.9079vw, 5.125rem);
        height: 100%;

        color: white;
        font-weight: 800;
        font-size: clamp(13px, 1.4vw, 22px);
        line-height: 1.27;
        letter-spacing: -0.05em;

        background-color: $main-color;
      }
    }

    .tournament-table {
      position: relative;
      z-index: 10;
      overflow: hidden;

      padding: 4px 21px;
      color: white;
      background-color: $second-color;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;

        height: 100%;
        width: 160px;

        background: url("~@/assets/Competition/Raffle/tournament-table-bg.svg") no-repeat;
      }

      @media screen and (max-width: 820px) {
        height: fit-content;
        padding: 8px clamp(0.3125rem, -0.3516rem + 3.125vw, 1.25rem);;
        margin-top: auto;

        & img {
          display: none;
        }
      }

      img, span {
        position: relative;
        z-index: 10;
      }

      span {
        font-weight: 500;
        font-size: clamp(10px, 0.85vw, 16px);
        line-height: 1.5;
        letter-spacing: -0.03em;

        @media screen and (max-width: 820px) {
          font-size: clamp(10px, 2.2vw, 16px);
        }
      }
    }

    .show-more {
      position: relative;
      z-index: 10;
      overflow: hidden;

      padding: 4px 21px;
      height: 100%;
      border: none;
      color: white;
      background-color: $second-color;

      @media screen and (max-width: 820px) {
        display: none;
      }

      span {
        font-weight: 500;
        font-size: clamp(10px, 0.85vw, 16px);
        line-height: 1.5;
        letter-spacing: -0.03em;

        @media screen and (max-width: 820px) {
          font-size: clamp(10px, 2.2vw, 16px);
        }
      }
    }

    #{$self}_subtitle {
      display: flex;
      margin-right: clamp(0.375rem, 0.2763rem + 0.3947vw, 0.75rem);

      img {
        width: clamp(1.5rem, 1.3026rem + 0.7895vw, 2.25rem);
        height: clamp(1.5rem, 1.3026rem + 0.7895vw, 2.25rem);

        margin-right: clamp(0.25rem, 0.1842rem + 0.2632vw, 0.5rem);
        margin-top: 3px;

        @media screen and (max-width: 820px) {
          width: clamp(1.6875rem, 0.125rem + 6.25vw, 2.9375rem);
          height: clamp(1.6875rem, 0.125rem + 6.25vw, 2.9375rem);
        }
      }

      span {
        margin-top: 2px;
        line-height: 1.25;
        letter-spacing: -0.03em;
        font-size: clamp(11px, 0.83vw, 16px);
        text-shadow: 1px 2px 4px #3c3b3b;

        @media screen and (max-width: 820px) {
          font-weight: 500;
          font-size: clamp(11px, 3vw, 21px);
          letter-spacing: -0.05em;
        }
      }
    }

    @media screen and (max-width: 820px) {
      .button-place, .button-point {
        display: none;
      }
    }
  }
</style>
